import React, {useState, useEffect, useRef} from "react";


// funct component qui récupère la props session ID
const ResumeSeat = ({seat, onDelete}) => {

    const handleUnselect = () => {
        onDelete(seat.id);
    }

    return (

        <div className="uk-accordion" data-id={seat.id}>
            <div style={{border: '0px'}}>
                <div>
                    <ul className="uk-iconnav uk-iconnav-vertical uk-float-right">
                        <li><a className="_deletePlaceLink" onClick={handleUnselect} uk-icon="trash"
                               title="Supprimer"></a></li>
                    </ul>
                    <span className="uk-margin-medium-top" style={{display: 'flex', flexDirection: 'column'}}>
                        <strong className="category">{seat.category}</strong>
                        <span className="zone">{seat.area}</span>
                    </span>
                </div>
                <div className="">
                    <div className="uk-overflow-auto">
                        <table className="uk-table uk-table-small"
                               style={{borderTop: '4px solid', borderTopColor: seat.color}}>
                            <colgroup>
                                <col width="33%"/>
                                <col width="33%"/>
                                <col width="33%"/>
                            </colgroup>
                            <thead>
                            <tr>
                                <th className="access" style={{textAlign: 'center', border: '1px solid #E6E6E6'}}>Rang
                                </th>
                                <th className="access"
                                    style={{textAlign: 'center', border: '1px solid #E6E6E6'}}>Siège
                                </th>
                                <th className="access" style={{textAlign: 'center', border: '1px solid #E6E6E6'}}>Type
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="row"
                                    style={{textAlign: 'center', border: '1px solid #E6E6E6'}}>{seat.row}</td>
                                <td className="seatNumber"
                                    style={{textAlign: 'center', border: '1px solid #E6E6E6'}}>{seat.seat}</td>
                                <td className="seatType"
                                    style={{textAlign: 'center', border: '1px solid #E6E6E6'}}>{seat.typeLabel}</td>
                            </tr>
                            </tbody>
                            <tfoot style={{borderTop: '4px solid', borderTopColor: '#E6E6E6'}}>
                            <tr style={{border: '1px solid #E6E6E6'}}>
                                <th className="tariff" colSpan="2"><span
                                    style={{marginLeft: '10px'}}>{seat.priceName}</span></th>
                                <td className="price" style={{textAlign: 'right'}}><span
                                    style={{marginRight: '10px'}}>{seat.priceLabel}</span></td>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    );

}

export default ResumeSeat;
