import $ from 'jquery';
import UIkit from "uikit";
require('../../../common/libs/jquery.syotimer.min');


$(document).ready(function() {

    var $timer = $('[data-timer="countdown"]');
    if ($timer.length) {

        var endCount = $timer.data('end'),
            nowServer = $timer.data('tare');

        var pattern = /(\d{4})\/(\d{2})\/(\d{2}) ([0-9]{2}):([0-9]{2}):([0-9]{2})/;
        var dtNowNavigator = new Date();
        var dtNowServer = new Date(nowServer.replace(pattern,'$2/$3/$1 $4:$5:$6'));
        var dtEndServer = new Date(endCount.replace(pattern,'$2/$3/$1 $4:$5:$6'));

        var difference = dtNowServer - dtNowNavigator;
        var dtEndNavigator = new Date(dtEndServer - difference);

        var $modal = $('[data-timer="modal"]');


        UIkit.util.on('[data-timer="modal"]', 'hide', function () {

            var redirectPath = $modal.attr('data-timer-path');
            if (redirectPath && redirectPath.length) {
                window.location = redirectPath;
            }
            else {
                window.location.reload();
            }

        });



        $timer.syotimer({
            year: dtEndNavigator.getFullYear(),
            month: dtEndNavigator.getMonth() + 1,
            day: dtEndNavigator.getDate(),
            hour: dtEndNavigator.getHours(),
            minute: dtEndNavigator.getMinutes(),
            second: dtEndNavigator.getSeconds(),
            layout: 'ms',
            dayVisible: false,
            afterDeadline: function(timerBlock) {

                UIkit.modal('[data-timer="modal"]').show();

            }
        });



    }

});
