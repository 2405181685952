import React, {useState, useEffect, useRef} from "react";
import ResumeSeat from "../../../../bleucitron/react/whitelabel/resume/components/ResumeSeat";
import MainContainer from "../../../../common/react/map/containers/MainContainer";
import NumberFormat from "react-number-format";
import $ from "jquery";

const ResumedPlan = (props) => {

    const childRef = useRef();
    const [total, setTotal] = useState(0);
    const [resumeSeats, setResumeSeats] = useState([]);
    const [isCategoryOpen, setCategoryOpen] = useState(false);
    const [detailPanelStyle, setDetailPanelStyle] = useState("");
    const [chevronDirection, setChevronDirection] = useState("up");

    const handleSelectedSeats = (selectedSeats, addedSeat) => {
        setResumeSeats([...selectedSeats]);
        setTotal(total + parseFloat(addedSeat.price));
    }

    const handleCloseModal = () => {
        $("#availabilityMap").modal("hide");
        $('.modal-backdrop').remove();
    }

    const handleClickCategories = () => {
        setCategoryOpen(!isCategoryOpen);
    }

    const closeCategories = () => {
        setCategoryOpen(false);
    }

    const book = () => {
        childRef.current.showLoader();
        childRef.current.book();
    }

    const handleUnselectSeat = (seatId) => {
        let updatedSeats = [];
        let updatedTotal = 0;
        resumeSeats.forEach((seat, index) => {

            if (seat.id !== seatId) {
                updatedSeats.push(seat);
                updatedTotal = updatedTotal + parseInt(seat.price);
            }

        });

        setResumeSeats(updatedSeats);
        setTotal(updatedTotal);

        childRef.current.unselect(seatId);
    }

    const toggleDetailPanel = () => {
        setDetailPanelStyle(chevronDirection === "up" ? "opened" : "");
        setChevronDirection(chevronDirection === "up" ? "down" : "up");
    }

    let lines = [];
    resumeSeats.forEach((seat, index) => {
        lines.push(
            <ResumeSeat key={seat.id} seat={seat} onDelete={handleUnselectSeat} />
        );

    });


    return (

        <div className={"plandyn-ACP "+(isCategoryOpen ? ' filtering' : '')} style={{width: '100%', height: '100%'}}>

            <div className={"uk-grid-divider"} style={{'background' : 'white'}} data-uk-grid>

                <div className={"selector _mainPlandyn uk-width-1-1 uk-width-expand@m"} >
                    <section className={"uk-card"}>
                        <div onClick={closeCategories} className={"uk-card-body"} id="map-js"
                             style={{width: '100%', height: '100%', 'boxSizing': 'border-box'}}>
                            <MainContainer
                                ref={childRef}
                                sessionId={props.sessionId}
                                sessionList={props.sessionList}
                                packId={props.packId}
                                cartId={props.cartId}
                                idwl={props.idwl}
                                maxSeats={props.maxSeats}
                                holders={props.holders}
                                autoload={props.autoload}
                                onBooked={props.onBooked}
                                onSeatSelected={handleSelectedSeats}
                                inBox={true}
                                fullVisible={true}
                            />
                        </div>
                    </section>
                </div>

                <div className={"_panierPlandyn selection uk-width-1-1 uk-width-1-3@m " + detailPanelStyle}>
                    <section className="uk-card uk-container uk-margin-auto-left@s">
                        <div className="uk-modal-header">
                            <span className="uk-card-title">
                                <div className="uk-total uk-float-left">
                                    <span className={"icon pull-left toggle-icon"}
                                          style={{'marginRight': '15px'}}><i
                                        className={"fas fa-map-pin"}></i></span>
                                    <span
                                        className="uk-text-bold">{resumeSeats.length} place{resumeSeats.length > 1 ? 's' : ''}</span>
                                </div>
                                <div className="uk-price uk-float-right">
                                    <span className="uk-text">
                                        <NumberFormat
                                            displayType={'text'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            decimalSeparator={","}
                                            value={total}
                                            suffix={"€"}
                                        />
                                    </span>
                                </div>
                            </span>
                        </div>
                        <div onClick={closeCategories} className="">
                            <div className="uk-text-center uk-margin-small-top uk-margin-small-bottom">
                                <div>Cliquez sur le plan pour choisir vos places, utilisez la molette
                                    pour zoomer.
                                </div>
                                <div className="uk-margin-small-top">Votre panier sera validé à l'étape
                                    suivante
                                </div>
                            </div>
                            <div className="" id="map-resume"
                                 style={{touchAction: 'pan-y', userSelect: 'none'}}>
                                {lines}
                            </div>
                        </div>
                        <div className="uk-modal-footer">
                            <div className="uk-grid uk-flex uk-flex-center"
                                 style={{textAlign: 'center'}}>
                                <div className={`uk-width-${resumeSeats.length ? '1-2' : '1-1'}`}>
                                    <a className="uk-button uk-button-lg uk-button-default uk-margin-bottom"
                                       onClick={handleCloseModal}>
                                        Annuler
                                    </a>
                                </div>
                                {resumeSeats.length ?
                                    <div className="uk-width-1-2">
                                        <a className="uk-button uk-button-default uk-button-primary uk-margin-bottom"
                                           onClick={book}>
                                            Réserver
                                        </a>
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                    </section>
                </div>

            </div>

        </div>

    );

}

export default ResumedPlan;

ResumedPlan.defaultProps = {
    autoload: false,
}
